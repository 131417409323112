<template>
  <div
    class="modal fade"
    id="addAsset"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card-modal">
          <form @submit.prevent="onSubmit">
            <div class="modal-header">
              <div class="modal-title text-center">{{ LabelModal }}</div>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label>Kode</label>
                <input
                  type="text"
                  class="form-control text-uppercase"
                  v-maska="'XXXX'"
                  v-model="formData.kode"
                  :disabled="!isNew"
                  @change="checkKode"
                  placeholder="Masukan kode kategori"
                />
                <div class="form-error" v-if="msgExist">{{ msgExist }}</div>
              </div>
              <div class="form-group">
                <label>Nama Kategori : </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Masukkan Nama Kategori"
                  v-model="formData.nama"
                  required="true"
                />
              </div>
              <div class="form-group">
                <label>Status</label>
                <Select2
                  v-model="formData.status"
                  :options="statusAset"
                  placeholder="Status Kategori"
                  @change="myChangeEvent($event)"
                  @select="mySelectEvent($event)"
                  required="true"
                />
              </div>
            </div>
            <div class="modal-footer" v-if="checkAccess('persediaan', 'cru_category_goods')">
              <!-- <button type="button" class="btn btn-close" data-dismiss="modal">Batal</button> -->
              <button type="submit" :disabled="isSubmit" class="btn btn-save">
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <section class="section">
        <div class="d-flex ml-3 mb-2 align-items-center">
          <div class="mr-auto">
            <ul class="nav nav-pills">
              <li class="nav-item">
                <router-link to="/data-supply" class="nav-link" v-if="checkAccess('persediaan', 'cru_goods')"
                  >Barang</router-link
                >
              </li>
              <li class="nav-item">
                <router-link to="/data-supply-kategori" class="nav-link active"
                  >Kategori Barang</router-link
                >
              </li>
            </ul>
          </div>

          <!-- <form class="search mr-3" @submit.prevent="onSearch">
            <input
              type="text"
              v-model="search"
              id="searchbox"
              @keyup="searchCheck"
              placeholder="Cari..."
              name="search"
            />
            <button type="submit"><i class="fa fa-search"></i></button>
          </form> -->

          <button @click="addData" class="btn-add" v-if="checkAccess('persediaan', 'cru_category_goods')">+ Tambah</button>
        </div>
        <div class="card-table">
          <div class="table-responsive">
            <table
              class="table table-bordered table-striped table-hover"
              id="tableGoodsCategory"
            >
              <thead>
                <tr>
                  <th style="width: 10%">Kode</th>
                  <th style="width: 55%">Nama Kategori</th>
                  <th style="width: 5%">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(value, index) in list"
                  :key="index"
                  v-on:click="detail(value.kode, value.nama, value.status)"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Klik untuk melihat detail"
                >
                  <td>{{ value.kode }}</td>
                  <td>{{ value.nama }}</td>
                  <td>
                    <div class="green-bedge" v-if="value.status == 'aktif'">
                      Aktif
                    </div>
                    <div class="red-bedge" v-else>Nonaktif</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../../components/Sidebar.vue";
import NavbarDashboard from "../../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";
import { cksClient, showAlert, checkModuleAccess } from "../../../../helper";
import {
  get_KategoriBarangCheckCode,
  get_KategoriBarangList,
  post_KategoriBarangSave,
} from "../../../../actions/barang/kategori";
import { maska } from "maska";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    // TableLite
  },

  directives: {
    maska,
  },

  data() {
    return {
      checkAccess: checkModuleAccess,
      statusAset: [
        { id: "aktif", text: "Aktif" },
        { id: "tidak", text: "Nonaktif" },
      ],
      dataTable: "",
      list: [],
      search: "",
      isSearch: "",
      id_company: cksClient().get("_account").id_company,
      isNew: false,
      msgExist: "",
      isSubmit: false,
      formData: {
        id_company: cksClient().get("_account").id_company,
        kode: "",
        nama: "",
        status: "aktif",
      },
      LabelModal: "Tambah Kategori Barang",
    };
  },

  mounted() {
    this.tooltip();
  },

  created() {
    this.getList();
  },

  beforeUnmount() {
    if (this.dataTable) {
      this.dataTable.destroy();
      this.dataTable = "";
    }
  },

  methods: {
    addData() {
      this.formData.kode = this.generateString(3);
      this.isNew = true;
      this.isCheckCode = "";
      this.checkKode();
      this.LabelModal = "Tambah Kategori Barang";
      $("#addAsset").modal("show");
    },

    checkKode() {
      if (this.formData.kode) {
        this.isCheckCode = true;
        this.msgExist = "Tunggu sebentar....";
        get_KategoriBarangCheckCode(
          {
            id_company: this.id_company,
            kode: this.formData.kode.toString().toLowerCase().toUpperCase(),
          },
          (res) => {
            console.log(res.is_exist);
            if (!res.is_exist) {
              this.isCheckCode = false;
              this.msgExist = "";
            } else {
              this.msgExist =
                "Kode kategori sudah terpakai, silakan gunakan kode yang lain";
            }
          },
          () => {
            this.msgExist = "Terjadi kesalahan, silakan ulangi kembali nanti";
          }
        );
      }
    },

    generateString(length) {
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
      let result = "";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }

      return result;
    },

    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },

    detail(kode, nama, status) {
      this.formData.kode = kode;
      this.formData.nama = nama;
      this.formData.status = status;
      this.LabelModal = "Edit Kategori Barang";
      $('[data-toggle="tooltip"]').tooltip("hide");
      $("#addAsset").modal("show");
    },
    searchCheck: function () {
      if (!this.search && this.isSearch) {
        this.isSearch = false;
        this.getList();
      }
    },
    onSearch: function () {
      if (this.search || this.isSearch) {
        this.isSearch = true;
        this.getList();
      }
      if (!this.search && this.isSearch) {
        this.isSearch = false;
      }
    },
    async getList() {
      await get_KategoriBarangList(
        {
          id_company: this.id_company,
          search: this.search,
        },
        (res) => {
          this.list = [];
          this.list = res.list;
          if (this.dataTable) {
            this.dataTable.destroy();
          }
          setTimeout(() => {
            this.dataTable = $("#tableGoodsCategory").DataTable({
              info: false,
              order: [[1, "asc"]],
            });
          }, 1000);
        },
        () => {
          this.list = [];
        }
      );
    },
    postData() {
      this.isSubmit = true;
      post_KategoriBarangSave(
        this.formData,
        (res) => {
          this.isSubmit = false;
          $("#addAsset").modal("hide");
          var msg =
            res.response_code == 201
              ? "Data kategori berhasil ditambahkan"
              : "Data kategori berhasil diperbarui";
          this.getList();
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: msg,
            showCancelButton: false,
          });
        },
        () => {
          $("#addAsset").modal("hide");
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi Kesalahan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },
    onSubmit() {
      showAlert(this.$swal, {
        title: "PERHATIAN!",
        msg: this.formData.kode
          ? "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?"
          : "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Lanjutkan",
        onSubmit: () => {
          this.postData();
        },
      });
    },
  },
};
</script>

<style scoped>
.card-table {
  margin-top: 0px !important;
}
</style>

<style scoped src="../../../../assets/css/style.css"></style>

<style scoped src="../../../../assets/css/custom.css"></style>
